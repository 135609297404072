import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import HomeNavBar from '../Component/HomeComponent/HomeNavBar'
import axios from 'axios'
import { hrport, port } from '../App'
import ArrowRght from '../SVG/ArrowRght'
import LocationIcon from '../SVG/LocationIcon'
import ClockIcon from '../SVG/ClockIcon'
import CurrencyIcon from '../SVG/CurrencyIcon'
import CalenderIcon from '../SVG/CalenderIcon'
import HrStore, { HrStorage } from '../Context/HrStore'
import RightAvailableJobs from '../Component/RightAvailableJobs'

const ParticularCareerPage = () => {
    const { id } = useParams()
    let { allJob } = useContext(HrStorage)
    let [jobDetails, setJobDetails] = useState()
    let [loading, setLoading] = useState(false)
    let getParticularData = () => {
        setLoading(true)
        axios.get(`${port}/api/jobs/${id}/`).then((response) => {
            setJobDetails(response.data)
            console.log(response.data, 'n');

        }).catch((error) => {
            console.log(error, 'job');
        })
    }
    useEffect(() => {
        getParticularData()
        window.scrollTo(0, 0)
    }, [id])
    let navigate = useNavigate()
    return (
        <div className='bg-blue-50 ' >
            <HomeNavBar />
            <main className='container mx-auto py-4  '>

                <button onClick={() => navigate(`/careers`)} className='rounded rotate-180 p-2 text-white bg-black px-3 ' >
                    <ArrowRght />
                </button>
                {/* Job Description */}
                <main className=' row my-3 ' >
                    {/* Left side */}
                    <section className='col-lg-8 col-md-9   '>
                        {jobDetails &&
                            <article className='bg-slate-50 shadow-sm rounded-xl p-3 ' >
                                <h5 className='fw-semibold poppins ' >  {jobDetails.Title} </h5>
                                <p className='text-sm text-slate-700 poppins ' > {jobDetails.company_inrto} </p>
                                <section className='flex flex-wrap gap-3 col-6 text-xs      fw-semibold poppins' >

                                    <div className='flex items-center gap-1 ' >
                                        <ClockIcon size={12} />
                                        {jobDetails.job_type}
                                    </div>
                                    <div className='flex items-center gap-1 ' >
                                        ₹
                                        {jobDetails.min_salary && " " + (jobDetails.min_salary) + jobDetails.salary_type}
                                        {jobDetails.min_salary && jobDetails.max_salary && " - "}
                                        {jobDetails.max_salary && (jobDetails.max_salary) + jobDetails.salary_type}

                                    </div>
                                    <div className='flex items-center gap-1 ' >
                                        <CalenderIcon size={12} />
                                        {jobDetails.min_exp && (jobDetails.min_exp) + "yrs"}
                                        {jobDetails.min_exp && jobDetails.Experience && " - "}
                                        {jobDetails.Experience && (jobDetails.Experience) + "yrs "}
                                    </div>
                                    <div className='flex items-center gap-1 ' >
                                        <LocationIcon size={12} />
                                        {jobDetails.job_location}
                                    </div>
                                </section>
                                <hr className='my-3 ' />
                                <section className='flex gap-3 justify-between text-sm items-center' >
                                    <div>
                                        <p> Posted : <span className='fw-semibold text-xs ' >{jobDetails.posted_on} </span> </p>

                                    </div>
                                    <button
                                        onClick={() =>
                                            window.open(`${hrport}/Canditate_Registration_Form?source=meridahr&desig=${jobDetails.Title}`,
                                                '_blank')}
                                        className={` bg-blue-700 flex my-2 ms-auto text-white text-sm rounded-full p-2 px-3 `} >
                                        Apply for job
                                    </button>
                                </section>

                            </article>
                        }
                        {
                            jobDetails &&
                            <article className='bg-slate-50 shadow-sm poppins my-3 rounded-xl p-3 ' >
                                <h6 className='fw-semibold text-lg ' >Job Description </h6>
                                <p className='text-sm my-3' > {jobDetails.Job_Discription} </p>
                                {jobDetails.points && jobDetails.points.length > 0 &&
                                    <div className=' fw-semibold  ' >
                                        <h6 className=' text-lg'> Responsibilities and Role : </h6>
                                        <div>
                                            <ul>
                                                {
                                                    jobDetails.points.map((obj) => (
                                                        <li className='block list-disc text-sm my-2 fw-normal ' >
                                                            {obj.point}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {jobDetails.expertise_points && jobDetails.expertise_points.length > 0 &&
                                    <div className=' fw-semibold  ' >
                                        <h6 className=' text-lg'> Expertise : </h6>
                                        <div>
                                            <ul>
                                                {
                                                    jobDetails.expertise_points.map((obj) => (
                                                        <li className='block list-disc text-sm my-2 fw-normal ' >
                                                            {obj.point}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {jobDetails.role && <h6 className='text-lg  ' > Role :
                                    <span className=' text-sm text-slate-700 fw-normal ' > {jobDetails.role} </span> </h6>}
                                {jobDetails.department_name && <h6 className='text-lg  ' > Department :
                                    <span className=' text-sm text-slate-700 fw-normal ' > {jobDetails.department_name} </span> </h6>}
                                {jobDetails.job_type && <h6 className='text-lg  ' > Employeement Type :
                                    <span className=' text-sm text-slate-700 fw-normal ' > {jobDetails.job_type} </span> </h6>}
                                {
                                    jobDetails.key_skills &&
                                    <div>
                                        <h6 className=' text-lg' > Key Skills </h6>
                                        <p className='text-sm text-slate-700 ' >Skills highlighted with ‘⭐‘ are preferred keyskills</p>
                                        <section className='flex flex-wrap gap-3 items-center ' >
                                            {
                                                jobDetails.key_skills.map((obj) => (
                                                    <div className='rounded-full w-fit text-xs p-2 border-2 ' >
                                                        {obj.is_required ? "⭐" : ''}  {obj.skill}
                                                    </div>
                                                ))
                                            }
                                        </section>

                                    </div>
                                }
                            </article>
                        }

                    </section>
                    {/* Right Side */}
                    <section className='col-lg-4 col-md-3 ' >
                        <article className='bg-slate-50 poppins shadow-sm  p-3 rounded-xl' >
                            <h5> Jobs you might be interested in</h5>
                            <RightAvailableJobs data={allJob} />

                        </article>

                    </section>

                </main>
            </main>

        </div>
    )
}

export default ParticularCareerPage