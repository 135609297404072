import React, { createContext, useEffect, useState } from 'react'
import { port } from '../App'
import axios from 'axios'


export const HrStorage = createContext()
const HrStore = ({ children }) => {
    let [active, setactive] = useState("")
    let [allJob, setAllJobs] = useState()
    let a = 'hellow'
    let getAllJobs = () => {
        axios.get(`${port}/api/job_description/`).then((response) => {
            setAllJobs(response.data)
        }).catch((error) => {
            console.log(error);

        })
    }
    useEffect(() => {
        getAllJobs()
    }, [])
    let value = { a, active, setactive,allJob }
    return (
        <HrStorage.Provider value={value} >
            {children}
        </HrStorage.Provider>
    )
}

export default HrStore