import React, { useEffect, useState } from 'react'
import FunnelIcon from '../../SVG/FunnelIcon'
import JobCard from './JobCard'
import RightSide from './RightSide'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { port } from '../../App'
import { useNavigate } from 'react-router-dom'

const JobSection = ({ jobs }) => {
    let [data, setdata] = useState()
    let navigate = useNavigate()
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setcurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [showOffcanvas, setOffcanvas] = useState(false)
    const itemsPerPage = 5;
    useEffect(() => {
        if (data) {
            const endOffset = itemOffset + itemsPerPage;
            setcurrentItems(data.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(data.length / itemsPerPage));
        }
    }, [itemOffset, data])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
        window.scroll(0, 0)
    };

    let getJob = () => {
        axios.get(`${port}/api/job_description/`).then((response) => {
            console.log(response.data);
            // setdata(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        getJob()
        if (jobs)
            setdata(jobs)
    }, [jobs])
    return (
        <div className='jobbg py-4' >
            <main className='row container  mx-auto poppins ' >
                <article className='col-sm-8 col-lg- ' >
                    <section className='flex justify-between items-center flex-wrap' >
                        <p className='mb-0 fw-semibold text-xl ' >{data && data.length} Jobs </p>
                        {/* <div className='flex gap-2 items-center border-1 bg-white rounded p-1 text-sm' >
                            <FunnelIcon size={20} />
                            <select name="" id="" className=' bg-white   outline-none w-32' >
                                <option value="" >Filter by </option>
                            </select>
                        </div> */}
                    </section>
                    {/* Jobs */}
                    <main className=' my-3' >
                        {
                            currentItems && currentItems.map((obj, index) => (
                                <div onClick={() => navigate(`/careers/${obj.slug}`)} className='cursor-pointer hover:scale-[1.02] duration-300 ' >

                                    <JobCard data={obj} />
                                </div>
                            ))
                        }
                        <ReactPaginate
                            breakLabel="..."
                            // nextLabel={<ArrowDown/>}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            pageCount={pageCount}
                            // previousLabel={null}
                            containerClassName='pagination'
                            pageLinkClassName='page-num'
                            previousLinkClassName='page-num'
                            nextLinkClassName='page-num'
                            activeLinkClassName='active'
                        />

                    </main>
                </article>
                {/* Right Side */}
                <article className='col-sm-4 col-lg- ' >
                    <RightSide />
                </article>
            </main>



        </div>
    )
}

export default JobSection