import React, { useContext, useEffect } from 'react'
import HomeNavBar from '../Component/HomeComponent/HomeNavBar'
import ContactForm from '../Component/ContactForm'
import LocationIcon from '../SVG/LocationIcon'
import MailIcon from '../SVG/MailIcon'
import PhoneIcon from '../SVG/PhoneIcon'
import { Helmet } from 'react-helmet'
import { HrStorage } from '../Context/HrStore'

const ContactPage = () => {
    let { active, setactive } = useContext(HrStorage)
    useEffect(() => {
        setactive('contact')
    }, [])
    return (
        <div className='contactbg ' >
            <Helmet>
                <title>Reach Us Today</title>
                <meta name="description" content=" Get in touch with our expert HR consultants today for personalized solutions tailored to your organization's needs. Let's drive success together!" />
                <link rel="canonical" href="https://www.meridahr.com/contact" />
            </Helmet>
            <HomeNavBar type='white'/>
            <ContactForm />
            <div className='container flex gap-3 mt-20 py-5 justify-center sm:justify-between flex-wrap mx-auto '>
                <div className='w-[20rem] xl:w-[20rem] sm:h-[10rem] bg-white shadow flex gap-3 items-center -sm inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-800 rounded-full '>
                        <LocationIcon size='20' />
                    </div>
                    <div className=''>
                        <p className='fw-semibold m-0 '> Address</p>
                        <p className='text-slate-500 text-sm '>  20-2 ,Keshava Krupa Building 2nd Floor, 30th cross, 10th Main Rd, 4th Block, Jayanagar, Bengaluru, Karnataka 560011
                        </p>
                    </div>
                </div>
                <div className='w-[20rem] xl:w-[20rem] h-[10rem] bg-white shadow flex gap-3 items-center  inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-800 rounded-full '>
                        <PhoneIcon size='20' />
                    </div>
                    <div>
                        <p className='fw-semibold m-0 '> Phone</p>
                        <p className='text-slate-500 m-0'>+91 8904599282 </p>
                        <p className='text-slate-500'>+91 9876543212 </p>
                    </div>
                </div>
                <div className='w-[20rem] xl:w-[20rem] h-[10rem] bg-white shadow flex gap-3 items-center inter p-4 rounded-2xl'>
                    <div className='p-3 text-white bg-blue-800 rounded-full '>
                        <MailIcon size='20' />
                    </div>
                    <div>
                        <p className='fw-semibold m-0 '> E-Mail Address</p>
                        <a href="mailto:info@skilllearning.com" className='text-decoration-none my-3 ' >
                            <p className='text-slate-500 text-xs xl:text-sm  '> info@skilllearning.com</p> </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactPage