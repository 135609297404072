import React from 'react'

const WhyChooseUs = () => {
    return (
        <div className='servicebg xl:min-h-[40vh] relative text-slate-50 poppins ' >
            <main className='row relative z-10 container mx-auto ' >
                <section className='col-lg-6 ' >
                    <p className='text-blue-600 ' >About US </p>
                    <p className='text-2xl sm:text-5xl xl:text-6xl michroma text-slate-50 '>
                        Why Choose <br /> US </p>
                </section>
                <section className='col-lg-6 fw-light tracking-wider ' >
                    <p>At Meirda HR Consulting, we are committed to helping your company succeed. Our dedicated team offers a full range of HR solutions designed to meet your unique needs. We understand the challenges of hiring and managing staff, and we are here to make the process easier for you.

                    </p>
                    <p className='my-4 ' >What sets us apart is our innovative in-house software that simplifies HR tasks. This helps us deliver excellent services efficiently and effectively. Count on us for our expertise, reliability, and commitment to
                        providing the best HR solutions for your company.</p>
                    {/* <div className='flex flex-wrap gap-20 ' >
                        <p>
                            <span className='text-2xl michroma sm:text-3xl ' >
                                150k+
                            </span> <br />
                            Service Center
                        </p>
                        <p>
                            <span className='text-2xl michroma sm:text-3xl ' >
                                180k+
                            </span> <br />
                            AI Programmed
                        </p>

                    </div> */}
                </section>
            </main>
            <main className='container relative z-10 mx-auto row mt-5 ' >
                <section className='col-lg-6 ' >
                    <h4 className='text-3xl sm:text-5xl ' >
                        Smart HR Solutions for a Smarter Workplace </h4>
                    <p className='text-sm w-10/12  ' >
                        Our HR solutions are built to simplify your work. From bringing on new team members to tracking attendance and managing payroll, we take care of it all. With our expert support and easy-to-use features, you’ll spend less time on paperwork and more time growing your business.
                        We're here to help you create a productive and positive work environment where everyone can do their best.
                    </p>
                </section>
                <section className='col-lg-6 py-5   ' >
                    <img className='rounded-xl bg-red-400 ' src={require('../../Assest/DashboardLight.webp')} alt="DashBoard" />

                </section>

            </main>
            <img className='absolute w-[24rem] z-0 right-0 -bottom-32 ' src={require('../../Assest/ecplise.webp')} alt="Ecplise" />

        </div>
    )
}

export default WhyChooseUs