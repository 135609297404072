import React, { useContext, useEffect, useState } from 'react'
import SearchBarSection from '../Component/CareersComponent/SearchBarSection'
import Explore from '../Component/CareersComponent/Explore'
import JobSection from '../Component/CareersComponent/JobSection'
import Footer from '../Component/Footer'
import HomeNavBar from '../Component/HomeComponent/HomeNavBar'
import { HrStorage } from '../Context/HrStore'
import axios from 'axios'
import { port } from '../App'

const CareersPage = () => {
    let { active, setactive } = useContext(HrStorage)
    useEffect(() => {
        setactive('career')
    }, [])
    let [allJob, setALlJobs] = useState()
    let [filteredData, setFilteredData] = useState()
    let [categoryJobs, setCategoryJobs] = useState()
    let getAllJobs = () => {
        axios.get(`${port}/api/job_description/`).then((response) => {
            setALlJobs(response.data)
            console.log(response.data, 'jobs');
            setFilteredData(response.data)
            const departmentCount = response.data.reduce((acc, job) => {
                const deptName = job.department_name;
                acc[deptName] = (acc[deptName] || 0) + 1;
                return acc;
            }, {});
            setCategoryJobs(departmentCount)
            console.log(departmentCount, 'jobs');
        }).catch((error) => {
            console.log(error, "error");
        })
    }
    useEffect(() => {
        getAllJobs()
    }, [])
    return (
        <div>
            <SearchBarSection />
            {/* <section className='cont w-full flex flex-col gap-3 ' >
                <div className='div1 ' >

                </div>
                <div className='div2 ' >

                </div>
                <div className='div3' >

                </div>
            </section> */}
            <Explore allJob={allJob} categoryJobs={categoryJobs} setFilteredData={setFilteredData} />
            <JobSection  jobs={filteredData} />
        </div>
    )
}

export default CareersPage